import {
  IAppSettings,
  IOpenAuthenticationOptions,
  ISeverityDefinition,
  ISystemOption,
  IThemeOption,
  ITranslationOption,
} from '@mobile-data-access-interfaces';

export class AppSettings implements IAppSettings {
  //#region Properties

  public apiUrl?: string;

  public i18n?: ITranslationOption;

  public openAuthenticationOptions: IOpenAuthenticationOptions[] = [];

  public systemTimeDifference?: { value: number; unit: string };

  public theme?: IThemeOption;

  public severities?: ISeverityDefinition[];

  public system?: ISystemOption;

  //#endregion
}
