import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import { catchError, map, mergeMap, Observable, of } from 'rxjs';
import {
  APP_SETTINGS_SERVICE,
  IAppSettingsService,
  ISystemService,
  SYSTEM_SERVICE,
} from '@mobile-data-access-services';
import { ScreenCodes, SystemStatuses } from '@mobile-data-access-enums';
import {
  ISmartNavigatorService,
  NavigateToScreenRequest,
  SMART_NAVIGATOR_SERVICE,
} from '@ui-tool/core';

@Injectable()
export class NotUnderMaintenanceGuard implements CanActivate {
  //#region Constructor

  public constructor(
    @Inject(SYSTEM_SERVICE) protected readonly _systemService: ISystemService,
    @Inject(SMART_NAVIGATOR_SERVICE)
    protected readonly _navigationService: ISmartNavigatorService,
    @Inject(APP_SETTINGS_SERVICE)
    protected readonly _appSettingService: IAppSettingsService
  ) {}

  //#endregion

  //#region Methods

  public canActivate(
    activatedRouteSnapshot: ActivatedRouteSnapshot,
    routerStateSnapshot: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this._appSettingService.loadSettingsAsync(false).pipe(
      mergeMap((settings) => {
        if (settings.system?.status === SystemStatuses.UNDER_MAINTENANCE) {
          return of(
            this._navigationService.buildUrlTree(ScreenCodes.MAINTENANCE)
          );
        }

        return this._systemService.getStatusAsync().pipe(
          map((status) => {
            if (status === SystemStatuses.UNDER_MAINTENANCE) {
              return this._navigationService.buildUrlTree(
                ScreenCodes.MAINTENANCE
              );
            }
            return true;
          })
        );
      }),
      catchError(() => {
        const navigationRequest = new NavigateToScreenRequest(
          ScreenCodes.MAINTENANCE
        );
        const urlTree = this._navigationService.buildUrlTree(
          navigationRequest.code,
          navigationRequest.routeParams as any,
          navigationRequest.extras
        );
        return of(urlTree);
      })
    );
  }

  //#endregion
}
